<template>
  <section class="text-left signature-container">
    <div class="signature-content">
      <span>I/WE, HEREBY DECLARE AND AGREE THAT:</span>
      <ul>
        <li>The above provided information and particulars are accurate, true and complete.</li>
        <li>This signature be the legally binding representation for all necessary documents (including but not limited to Application Form and Claim Request Form) related to this Application.</li>
        <li>The insurance coverage applying will only take effect when this application has been accepted by the insurance company and the required premium has been fully settled.</li>
      </ul>
    </div>
    <div class="signature-content mg-t-32">
      <span>本人/我們聲明並同意:</span>
      <ul>
        <li>以上資料均準確無誤，真實及屬於事實之全部。</li>
        <li>本簽署作為所有所需申請文件之簽署(包括但不限於投保申請書及保險索償申請表)並具有相關之法律效力。</li>
        <li>所申請的保障必須獲得保險公司接納並完成繳交應付保費後才正式生效。</li>
      </ul>
    </div>
    <section class="ipad-block">
    </section>
    <v-sign
      ref="v-sign"
      v-model="haveSign"
      :width="480"
      :height="286"
      :signType="signType"
      :company-name="companyName"
      class="mg-t-32 mg-b-32"></v-sign>

    <!--    <section class="mobile-block">-->
    <!--      <v-sign-->
    <!--        ref="v-sign"-->
    <!--        v-model="haveSign"-->
    <!--        :width="335"-->
    <!--        :height="200"-->
    <!--        :signType="signType"-->
    <!--        :company-name="companyName"-->
    <!--        class="mg-t-32 mg-b-32"></v-sign>-->
    <!--    </section>-->
    <v-button
      :disabled="!haveSign"
      @click="onConfirm">{{haveSign?'遞交 Submit':'下一步 Next'}} </v-button>

    <!--      </div>-->
  </section>
</template>

<script>
import '@/jslib/esign'
import VSign from '@/components/controls/v-sign'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import MixinsPolicyForm from '@/mixins/policyForm'
import {saveMotorQuotation} from '@api/motorOrder'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'signature',
  components: {
    VSign
  },
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  data() {
    return {
      haveSign:false,
      signData: {},
    }
  },
  computed: {
    isDisable(){
      return this.confirm===''
    },
    companyName(){
      let {holder_company_name} = this.quoteInformationForm||{}
      return holder_company_name||''
    },
    signType(){
      let {client_type} = this.quoteInformationForm||{}
      return client_type||''
    }
  },
  mounted () {
    // this.height = element.clientWidth +20
  },
  methods: {
    ...mapActions('quotation',['setQuoteInformationForm','setQuotationData']),
    async onConfirm(){
      const result = await this.$refs['v-sign'].handlerGenerate()
      if(result) {
        const { file_id } = result
        // this.$set(this.signData, 'sign_pic_path', name)
        this.$set(this.signData, 'sign_file_id', file_id)
        this.handleConfirm()
        // this.$router.push('/quoting/insure/success')
      }
    },
    handleConfirm(){
      let params =JSON.parse(JSON.stringify({
        ...this.quoteInformationForm,
        ...this.signData,
        class_id:1003
      }))
      this.network().saveQuotation(params)
    },
    network(){
      return {
        saveQuotation: async (params) => {
          // delete params.holder_company_nature
          let {policy_holder_id_type,policy_holder_pp_no,driver=[]} = params||{}
          if(policy_holder_id_type==2){
            params.policy_holder_id_no  = policy_holder_pp_no
          }
          driver.forEach(item=>{
            if(item.id_type==2){
              item.id_no = item.pp_no
            }
          })
          let {data}= await saveMotorQuotation(params)
          this.setQuoteInformationForm(null)
          this.setQuotationData(data)
          this.$router.push('/quoting/insure/success')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.signature-container{
  .signature-content{
    span{
      font-weight: 500;
      font-size: 16px;
      line-height: 175%;
      letter-spacing: 0.02em;
      color: #1A1A1C;
      display: inline-block;
      margin-bottom: 16px;
    }
    ul{
      list-style-type: decimal;
      padding-left: 20px;
      li{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 175%;

        letter-spacing: 0.02em;


        color: #1A1A1C;
      }
    }
  }
}
@media screen and (min-width: 520px){
  .mobile-block{
    display: none;
  }
}
@media screen and (max-width: 519px){
  .ipad-block{
    display: none;
  }
}
</style>
